import React from 'react';
import '../App.css';
import './ClinicalSupervision.css'
import { motion } from 'framer-motion';
import Header from './CSServicesHeader.png';
import IS from './organic.png';
import { Link } from 'react-router-dom';
import DS from './organic (1).png';
import GS from './organic (2).png';

class CSServices extends React.Component {
    render() {
        return (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
            >
                <h1>Supervision Services</h1>
                <p>Good supervision is integral for the development of an effective and competent psychotherapist. At Tall Tree Psychotherapy, we offer multiple options for you to access the supervision format that best suits your needs and your budget.</p>
                <div className='twocol'>
                    <img src={IS} className='twoimg' />
                    <p>
                        <b>Individual</b> supervision is offered by the hour, and you choose the number of hours and frequency, based on your experience level, need for supervision to gain CRPO hours, or for your own professional development. For more information, please visit <Link to="/clinical-supervision/faq">our faq</Link> section.
                        <br /><br />
                        <Link to="/clinical-supervision/fees">
                            Click here to see our fees
                        </Link>
                    </p>
                </div>
                <div className='division' />
                <div className='twocol'>
                    <img src={DS} className='twoimg' />
                    <p>
                        If you want to double up on your supervision hours and gain knowledge from the experience of others, dyadic* supervision is a cost-effective way to accomplish this. Dyadic supervision is typically offered for two hours at a time, with one hour focused on your personal client work. The best part is, both hours count as individual supervision hours with the CRPO. You can bring a partner with you or get matched* with another Tall Tree Psychotherapy supervisee. For more information, please visit <Link to="/clinical-supervision/faq">our faq</Link> section.
                        <br /><br />
                        <i>*Matching will be based on availability</i>
                        <br /><br />
                        <Link to="/clinical-supervision/fees">
                            Click here to see our fees
                        </Link>
                    </p>
                </div>
                <div className='division' />
                <div className='twocol'>
                    <img src={GS} className='twoimg' />
                    <p>
                        Learn from the experience of others and receive feedback on clinical matters from an experienced supervisor and a wider network of your peers with our group supervision. This is a more affordable way to achieve your goals in attaining your supervision hours and professional development. Group supervision is typically offered for two hours at a time, with a half hour focused on your personal client work.
                        <br /><br />
                        <i>*Frequency and length of group supervision will be dependent on your status with, and requirements for, the CRPO, as well as your personal needs. This will be discussed during your discovery call.</i>
                        <br /><br />
                        <Link to="/clinical-supervision/fees">
                            Click here to see our fees
                        </Link>
                    </p>
                </div>
                <br /><br /><br /><br />
                <Link to="/clinical-supervision/contact" className="link-button">
                    Contact Us
                </Link>
                <br /><br /><br /><br />
            </motion.div>
        )
    }
}

export default CSServices;