import React from 'react';
import '../App.css';
import './ClinicalSupervision.css'
import { motion } from 'framer-motion';
import Header from './CSSupervisors.png';
import { Link } from 'react-router-dom';
import Matt from './Matt.png';
import Tracy from '../Tracy.jpg';

class CSStory extends React.Component {
    render() {
        return (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
            >
                <h1 className='full-heading'>Tracy Bertrim</h1>
                <div className='twocol rev'>
                    <div>
                        <p>Tracy Bertrim (she/her) is a graduate of the Master of Arts in Counselling and Spirituality program at Saint Paul University. Tracy is passionate about therapist development and provides a safe, nonjudgemental space for supervisees to learn, challenge themselves, explore, and grow.</p>
                        <p>Clinically, Tracy has extensive experience working with families and individuals of all ages and with various needs. Her clinical specializations include anxiety, ADHD, and trauma, and her approach to her client work is trauma-informed and person-centered. She seeks to create individualized treatment plans for each client by pulling from various modalities, including emotionally focused individual therapy, narrative therapy, DBT, and CBT.</p>
                    </div>
                    <img src={Tracy} className='twoimg port' />
                </div>
                <h1 className='full-heading'>Matt Temple</h1>
                <div className='twocol'>
                    <img src={Matt} className='twoimg port' />
                    <div>
                        <p>Matt Temple (he/him) graduated from Saint Paul University with a Master of Arts in Spirituality and Counselling with a specialization in couples’ work. Matt’s approach to therapy is grounded in an existential lens, and he works relationally with clients.  He approaches supervision in a similar manner and is passionate about the ongoing development and growth of his clients, supervisees, and himself.</p>
                        <p>Matt has completed the Advanced Training at the Self-In-Relationship Institute (SIRP), formerly known as the Ottawa Institute for Object Relations, as well as the Clinical and Supervision Course and Training.</p>
                    </div>
                </div>
                <br /><br /><br />
                <hr />
                <div className='mission'>
                    <h2>Our Mission</h2>
                    <p>To provide competent supervision by promoting the professional development and skills enhancement of psychotherapists to create safety for their clients.</p>
                </div>
                <br /><br /><br /><br />
            </motion.div>
        )
    }
}

export default CSStory;