import React from 'react';
import './App.css';
import './clinical-supervision/ClinicalSupervision.css'
import { AnimatePresence, motion } from 'framer-motion';
import Tracy from './Tracy.jpg';
import Mycha from './MychaPic.jpeg';
import Solmaz from './SolmazPic.jpeg';
import Yulia from './YuliaPic.jpg';
import { tracy1, tracy2, solmaz1 } from './TextContent.json';
import { Link } from 'react-router-dom';


class About extends React.Component {
    render() {
        return (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
            >
                <div className="about-top-container">
                    <h1>
                        Welcome to Tall Tree Psychotherapy!
                    </h1>
                    <div className='about-paragraph'>
                        We are committed to providing a safe and inclusive space for all people, regardless of race, culture, gender, sexual identity, social status, religious affiliation, age, or disability.
                    </div><br></br>
                    <h1 className='full-heading t-page'>
                        Tracy Bertrim
                        <p>Virtual & In-Person Services</p>
                    </h1>
                    <div className='t-stats'>
                        <div>
                            <p>Populations Served</p>
                            <ul>
                                <li>Children</li>
                                <li>Adolescents</li>
                                <li>Adults</li>
                            </ul>
                        </div>
                        <div>
                            <p>Therapy Formats</p>
                            <ul>
                                <li>Individual</li>
                                <li>Family</li>
                            </ul>
                        </div>
                        <div>
                            <p>Main Areas of Focus</p>
                            <ul>
                                <li>Anxiety, Phobias</li>
                                <li>ADHD</li>
                                <li>Trauma and PTSD</li>
                                <li>First Responders and Frontline Workers</li>
                            </ul>
                        </div>
                    </div>
                    <div className='twocol rev'>
                        <div>
                            <p>{tracy1}</p>
                            <p>{tracy2}</p>
                        </div>
                        <img src={Tracy} className='twoimg port' />
                    </div>
                    <h1 className='full-heading t-page'>
                        Solmaz Barghgir
                        <p>Virtual Services</p>
                    </h1>
                    <div className='t-stats'>
                        <div>
                            <p>Populations Served</p>
                            <ul>
                                <li>Adolescents</li>
                                <li>Adults</li>
                            </ul>
                        </div>
                        <div>
                            <p>Therapy Formats</p>
                            <ul>
                                <li>Individual</li>
                                <li>Family</li>
                            </ul>
                        </div>
                        <div>
                            <p>Main Areas of Focus</p>
                            <ul>
                                <li>Anxiety</li>
                                <li>Relationship Issues</li>
                                <li>Self Esteem</li>
                            </ul>
                        </div>
                    </div>
                    <div className='twocol'>
                        <img src={Solmaz} className='twoimg port' />
                        <div>
                            <p>{solmaz1}</p>
                            <p>Recognizing the importance of collaboration, Solmaz believes in the shared responsibility
                                between counsellor and client in the therapeutic journey. <i>Solmaz tailors integrated interventions
                                    to address individual needs using diverse therapeutic approaches</i> from a range of evidence-
                                based modalities, including Art Therapy, Attachment-based Coaching, Cognitive Behavioural
                                (CBT), Emotionally Focused, Multicultural, Narrative, and Solution-Focused Brief (SFBT) to
                                tailor treatment plans that would effectively soothe clients' needs.</p>
                        </div>
                    </div>
                    <h1 className='full-heading t-page'>
                        Mycha Haughton-Lewis
                        <p>Virtual Services</p>
                    </h1>
                    <div className='t-stats'>
                        <div>
                            <p>Populations Served</p>
                            <ul>
                                <li>Adolescents</li>
                                <li>Adults</li>
                            </ul>
                        </div>
                        <div>
                            <p>Therapy Formats</p>
                            <ul>
                                <li>Individual</li>
                                <li>Couples</li>
                            </ul>
                        </div>
                        <div>
                            <p>Main Areas of Focus</p>
                            <ul>
                                <li>Anxiety</li>
                                <li>Self Identity</li>
                                <li>ADHD</li>
                                <li>OCD</li>
                                <li>BPD</li>
                            </ul>
                        </div>
                    </div>
                    <div className='twocol rev'>
                        <div>
                            <p>Mycha (she/her) is a registered psychotherapist (qualifying) with over a year of clinical
                                experience who has worked with individuals with diverse concerns. Mycha ensures she
                                provides a welcoming and safe environment for clients to explore their thoughts and
                                concerns from a non-judgmental approach. Mycha believes the client is the expert of
                                their own life which means the client plays a significant role in the therapeutic process.
                                In addition, Mycha&#39;s approach is client-centered, ensuring a collaborative relationship
                                between client and therapist. Mycha is aware that the therapeutic process is different for
                                each client. She incorporates evidence-based treatments such as Acceptance and
                                Commitment Therapy (ACT), Dialectical Behavioural Therapy (DBT) and Interpersonal
                                and Social Rhythm Therapy (IPSRT) to provide a treatment plan based on the client&#39;s
                                treatment goals.</p>
                        </div>
                        <img src={Mycha} className='twoimg port' />
                    </div>
                    <h1 className='full-heading t-page'>
                        Yulia Saveliev
                        <p>Virtual Services</p>
                    </h1>
                    <div className='t-stats'>
                        <div>
                            <p>Populations Served</p>
                            <ul>
                                <li>Adults</li>
                            </ul>
                        </div>
                        <div>
                            <p>Therapy Formats</p>
                            <ul>
                                <li>Individual</li>
                            </ul>
                        </div>
                        <div>
                            <p>Main Areas of Focus</p>
                            <ul>
                                <li>ADHD</li>
                                <li>Depression</li>
                                <li>Anxiety</li>
                                <li>Emotion Regulation</li>
                                <li>Relationship Issues</li>
                            </ul>
                        </div>
                    </div>
                    <div className='twocol'>
                        <img src={Yulia} className='twoimg port' />
                        <div>
                            <p>
                                Yulia (she/her) is a registered psychotherapist with the College of Registered Psychotherapists of Ontario. Yulia is a compassionate psychotherapist with over 10 years of experience in the mental health field, dedicated to helping individuals from diverse backgrounds find balance in their lives. Yulia values diversity and inclusion, recognizing that each person's unique experiences and identity play a significant role in their journey. Understanding that life is often filled with challenges, Yulia believes that embracing struggle is an essential part of personal growth. She specializes in supporting clients with ADHD, depression, anxiety, emotion regulation, and relationship issues.</p>
                            <p>Using an eclectic approach, Yulia draws from modalities including Dialectical Behavior Therapy (DBT), Cognitive Behavioral Therapy (CBT), Narrative Therapy, and Motivational Interviewing, tailoring her approach to each client's unique needs. By fostering resilience, inclusivity, and a sense of purpose, Yulia works collaboratively with clients to overcome obstacles and move toward their goals, navigating life’s complexities and creating lasting, meaningful change.</p>
                        </div>
                    </div>
                </div>
                <br /><br />
                <div className="link-button"><Link to='/psychotherapy-services/contact'>Interested in services? Click here</Link></div>
            </motion.div>
        )
    }
}

export default About;