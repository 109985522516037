import React from 'react';
import '../App.css';
import './ClinicalSupervision.css'
import { motion } from 'framer-motion';
import Header from './FeesHeader.jpg'
import { Link } from 'react-router-dom';


class CSFees extends React.Component {
    render() {
        return (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
            >
                <h1>Pay-As-You-Go</h1>
                <table>
                    <tr>
                        <th>Supervision Type</th>
                        <th>Cost Per Supervisee</th>
                        <th># of Hours Obtained</th>
                    </tr>
                    <tr>
                        <td>Individual</td>
                        <td>$150</td>
                        <td>1 Hour</td>
                    </tr>
                    <tr>
                        <td>Dyadic</td>
                        <td>$150</td>
                        <td>2 Hours (1 hour to present)</td>
                    </tr>
                    <tr>
                        <td>Group</td>
                        <td>$75</td>
                        <td>2 Hours (30 minutes to present)</td>
                    </tr>
                </table>
                <br /><br /><br />
                <h1>Pre-Paid Package Options</h1>
                <p className='left'>Tall Tree Psychotherapy also offers pre-paid package options to help reduce the costs associated with requiring more frequent supervision – the more supervisions you purchase, the greater the discount.</p>
                <p className='left'><i>*Packages must be paid for in full prior to starting the supervisions (beginning of each month), and completed by the end of each month. Applies to individual and dyadic supervisions</i></p>
                <table>
                    <tr>
                        <th>Package</th>
                        <th>Number of Supervisions</th>
                        <th>Cost Per Hour</th>
                    </tr>
                    <tr>
                        <td>Package A</td>
                        <td>Two Supervisions / Month</td>
                        <td>$140 ($280 Total)</td>
                    </tr>
                    <tr>
                        <td>Package B</td>
                        <td>Three Supervisions / Month</td>
                        <td>$130 ($390 Total)</td>
                    </tr>
                    <tr>
                        <td>Package C</td>
                        <td>Four Supervisions / Month</td>
                        <td>$120 ($480 Total)</td>
                    </tr>
                </table>
                <br /><br /><br /><br />
                <Link to="/clinical-supervision/contact" className="link-button">
                    Click Here to Get Started
                </Link>
                <br /><br /><br /><br />
            </motion.div>
        )
    }
}

export default CSFees;