import React from 'react';
import '../App.css';
import './ClinicalSupervision.css'
import Topbar from '../Topbar';
import Footer from '../Footer';
import { motion } from 'framer-motion';
import { Link, Route } from 'react-router-dom';
import CSFees from './CSFees';
import CSServices from './CSServices';
import CSStory from './CSStory';
import CSFAQ from './CSFAQ';
import CSContact from './CSContact';

const subMap = {
    "fees": "Fees",
    "supervisors": "Supervisors",
    "faq": "FAQ",
    "contact": "Contact"
}

class ClinicalSupervision extends React.Component {
    render() {
        const { match } = this.props;

        return (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
            >
                <Topbar />
                <div className="header-image-cs-services cs-header">
                    <div className="services1">Clinical Supervision</div>
                    <p className='cs-subheading'>{subMap[this.props.location.pathname.split("/")[2]] || "Supervision Services"}</p>
                </div>
                <div className='cs-container'>
                    <ul className='cs-subnav'>
                        <Link to="/clinical-supervision" className="cslink">
                            <li>Supervision Services</li>
                        </Link>
                        <Link to={`${match.url}/fees`} className="cslink">
                            <li>Fees</li>
                        </Link>
                        <Link to={`${match.url}/supervisors`} className="cslink">
                            <li>Supervisors</li>
                        </Link>
                        <Link to={`${match.url}/faq`} className="cslink">
                            <li>FAQ</li>
                        </Link>
                        <Link to={`${match.url}/contact`} className="cslink">
                            <li>Contact</li>
                        </Link>
                    </ul>
                    <Route path={`${match.path}`} exact component={CSServices} />
                    <Route path={`${match.path}/fees`} component={CSFees} />
                    <Route path={`${match.path}/supervisors`} component={CSStory} />
                    <Route path={`${match.path}/faq`} component={CSFAQ} />
                    <Route path={`${match.path}/contact`} component={CSContact} />
                </div>
                <Footer />
            </motion.div >
        )
    }
}

export default ClinicalSupervision;