import React from 'react';
import './App.css';
import { Link } from 'react-router-dom';
import menu from './menu-icon.png';
import Slide from '@material-ui/core/slide';

class SideNavbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expand: false,
            csExpand: false
        }
        this.setExpand = this.setExpand.bind(this);
        this.setCsExpand = this.setCsExpand.bind(this);
    }

    setExpand(index) {
        this.setState(prevState => ({ expand: !prevState.expand }));
    }

    setCsExpand(index) {
        this.setState(prevState => ({ csExpand: !prevState.csExpand }));
    }

    render() {
        return (
            <div class="side-navbar">
                <div className="side-items">
                    <div className="side-container"><Link to='/' className="side-item">HOME</Link></div>
                    <div>
                        <div className="side-container">
                            <div className="flex-center">
                                <Link className='subnav-container side-item' to='/psychotherapy-services' onClick={() => this.props.onClose()}>PSYCHOTHERAPY SERVICES</Link>
                                <span class="material-symbols-outlined expander" onClick={this.setExpand}>
                                    {this.state.expand ? "expand_less" : "expand_more"}
                                </span>
                            </div>
                        </div>
                        <Slide direction="right" in={this.state.expand} mountOnEnter unmountOnExit>
                            <div className='subnav' >
                                <div className="side-container" onClick={() => this.props.onClose()}><Link to='/psychotherapy-services' className="side-item">SERVICES</Link></div>
                                <div className="side-container" onClick={() => this.props.onClose()}><Link to='/psychotherapy-services/therapists' className="side-item">OUR THERAPISTS</Link></div>
                                <div className="side-container" onClick={() => this.props.onClose()}><Link to='/psychotherapy-services/faq' className="side-item">FAQ</Link></div>
                                <div className="side-container" onClick={() => this.props.onClose()}><Link to='/psychotherapy-services/contact' className="side-item">CONTACT</Link></div>
                            </div>
                        </Slide>
                    </div>
                    <div>
                        <div className="side-container">
                            <div className="flex-center">
                                <Link className='subnav-container side-item' to='/clinical-supervision' onClick={() => this.props.onClose()}>CLINICAL SUPERVISON</Link>
                                <span class="material-symbols-outlined expander" onClick={this.setCsExpand}>
                                    {this.state.csExpand ? "expand_less" : "expand_more"}
                                </span>
                            </div>
                        </div>
                        <Slide direction="right" in={this.state.csExpand} mountOnEnter unmountOnExit>
                            <div className='subnav' >
                                <div className="side-container" onClick={() => this.props.onClose()}><Link to='/clinical-supervision' className="side-item">SUPERVISION SERVICES</Link></div>
                                <div className="side-container" onClick={() => this.props.onClose()}><Link to='/clinical-supervision/fees' className="side-item">FEES</Link></div>
                                <div className="side-container" onClick={() => this.props.onClose()}><Link to='/clinical-supervision/supervisors' className="side-item">SUPERVISORS</Link></div>
                                <div className="side-container" onClick={() => this.props.onClose()}><Link to='/clinical-supervision/faq' className="side-item">FAQ</Link></div>
                                <div className="side-container" onClick={() => this.props.onClose()}><Link to='/clinical-supervision/contact' className="side-item">CONTACT</Link></div>
                            </div>
                        </Slide>
                    </div>
                </div>
            </div >
        )
    }
}

export default SideNavbar;