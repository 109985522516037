import React from 'react';
import '../App.css';
import './ClinicalSupervision.css'
import { motion } from 'framer-motion';
import emailjs from 'emailjs-com';

class CSContact extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            contactMethod: [],
            service: [],
            message: ""
        }
        this.setContact = this.setContact.bind(this);
        this.setService = this.setService.bind(this);
    }

    submit(e) {
        e.preventDefault();
        this.setState({
            message: "Sending message..."
        });
        if (this.state.service.length === 0) {
            this.setState({
                message: "Please select at least 1 service you are interested in."
            });
        }
        if (this.state.contactMethod.length === 0) {
            this.setState({
                message: "Please select at least 1 contact method."
            });
        }
        emailjs.sendForm('service_hdyjw5q', 'template_bqijb1i', e.target, 'user_ebmznMDN64Ho5dSu05f3i')
            .then((result) => {
                this.setState({
                    message: "Thank you for your message. I will respond to you within 24 hours."
                });
                console.log(result.text);
                e.target.reset();
            }, (error) => {
                console.log(error.text);
                this.setState({
                    message: "Error sending email. Please try again later."
                });
            });
    }

    setContact(method) {
        this.setState(prevState => {
            const contactMethod = prevState.contactMethod.includes(method)
                ? prevState.contactMethod.filter(m => m !== method)
                : [...prevState.contactMethod, method];

            return { contactMethod };
        });
    }

    setService(serv) {
        this.setState(prevState => {
            const service = prevState.service.includes(serv)
                ? prevState.service.filter(m => m !== serv)
                : [...prevState.service, serv];

            return { service };
        });
    }

    render() {
        return (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
            >
                <h1>Interested in Clinical Supervision Services?<br />
                    Get Started Here</h1>
                <p><i>Complete the following form and we will be in touch to get you started!</i></p>
                <hr />
                <form onSubmit={this.submit.bind(this)} className='form'>
                    <p className='label'>Name *</p>
                    <div className='two-inp'>
                        <input type="text" placeholder='First' name="first" required />
                        <input type="text" placeholder='Last' name="last" required />
                    </div>
                    <p className='label'>Email</p>
                    <input type="text" name="email" />
                    <p className='label'>Phone Number</p>
                    <input type="text" name="phone" />
                    <p className='label'>How would you prefer to be contacted? *</p>
                    <p><input type="checkbox" className="contact-checkbox" onChange={() => this.setContact("email")} />Email</p>
                    <p><input type="checkbox" className="contact-checkbox" onChange={() => this.setContact("phone")} />Phone</p>
                    <input type='hidden' value={this.state.contactMethod.toString()} name='contact' />
                    <p className='label'>What service(s) are you interested in? *</p>
                    <p><input type="checkbox" className="contact-checkbox" onChange={() => this.setService("Individual Supervision")} />Individual Supervision</p>
                    <p><input type="checkbox" className="contact-checkbox" onChange={() => this.setService("Dyadic Supervision")} />Dyadic Supervision</p>
                    <p><input type="checkbox" className="contact-checkbox" onChange={() => this.setService("Group Supervision")} />Group Supervision</p>
                    <p><input type="checkbox" className="contact-checkbox" onChange={() => this.setService("Community Rooms")} />Community Rooms</p>
                    <p><input type="checkbox" className="contact-checkbox" onChange={() => this.setService("Unsure")} />I’m not sure yet!</p>
                    <input type='hidden' value={this.state.service.toString()} name='service' />
                    <p className='label'>Comment or Message</p>
                    <div><textarea placeholder="Enter your message..." rows="8" name="message" /></div>
                    <br />
                    <div>{this.state.message}</div>
                    <br />
                    <button type="submit" className="link-button submit">Submit</button>
                    <p className='info'>supervision@talltreepsychotherapy.ca</p>
                    <p className='info'>647-577-9055</p>
                </form>
            </motion.div>
        )
    }
}

export default CSContact;