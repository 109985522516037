import React from 'react';
import './App.css';
import logo from './Logo-No-Background.png';
import words from './Tall-Tree-Lettering-No-Background.png';
import { Link } from 'react-router-dom';
import HomePic from './Services1.jpg';
import AboutPic from './About2.jpg';
import LearnPic from './LearnMain.jpg';
import ServicesPic from './Services4.jpg';
import FAQPic from './FAQMain.jpg';
import ContactPic from './ContactMain.jpg';
import Fade from '@material-ui/core/Fade';
import { AnimatePresence, motion } from 'framer-motion';
import CSPic from './cs.png';

class Home2 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            home: true,
            about: false,
            learn: false,
            services: false,
            faq: false,
            contact: false,
            cs: false
        }
        this.switchAbout = this.switchAbout.bind(this);
        this.switchHome = this.switchHome.bind(this);
        this.switchLearn = this.switchLearn.bind(this);
        this.switchServices = this.switchServices.bind(this);
        this.switchFAQ = this.switchFAQ.bind(this);
        this.switchContact = this.switchContact.bind(this);
        this.switchCS = this.switchCS.bind(this);
    }

    switchHome() {
        this.setState({
            home: true,
            about: false,
            learn: false,
            services: false,
            faq: false,
            contact: false
        });
    }

    switchAbout() {
        this.setState({
            home: false,
            about: true,
            learn: false,
            services: false,
            faq: false,
            contact: false,
            cs: false
        });
    }

    switchLearn() {
        this.setState({
            home: false,
            about: false,
            learn: true,
            services: false,
            faq: false,
            contact: false,
            cs: false
        });
    }

    switchServices() {
        this.setState({
            home: false,
            about: false,
            learn: false,
            services: true,
            faq: false,
            contact: false,
            cs: false
        });
    }

    switchFAQ() {
        this.setState({
            home: false,
            about: false,
            learn: false,
            services: false,
            faq: true,
            contact: false,
            cs: false
        });
    }

    switchContact() {
        this.setState({
            home: false,
            about: false,
            learn: false,
            services: false,
            faq: false,
            contact: true,
            cs: false
        });
    }

    switchCS() {
        this.setState({
            home: false,
            about: false,
            learn: false,
            services: false,
            faq: false,
            contact: false,
            cs: true
        });
    }

    render() {
        return (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
            >
                <div className="title-cont">
                    <div className="home-title">
                        <img src={logo} alt="logo" height="90%" class="home-logo-1" />
                        <img src={words} alt="title" height="100%" />
                    </div>
                    <div className="home-words"><div>Tracy Bertrim, M.A.,&nbsp;</div><div>Registered Psychotherapist</div></div>
                </div>
                <div className="home-container">
                    <div className="home-nav">
                        <div className="home-items">
                            <Link to='/psychotherapy-services' className="home-item" onMouseOver={this.switchServices} onMouseLeave={this.switchHome}>PSYCHOTHERAPY SERVICES</Link>
                            <Link to='/clinical-supervision' className="home-item" onMouseOver={this.switchCS} onMouseLeave={this.switchHome}>CLINICAL SUPERVISION</Link>
                        </div>
                    </div>
                    <div className="home-pic">
                        <Fade in={this.state.home} timeout={500}><img src={HomePic} alt="home" className="home-img" /></Fade>
                        <Fade in={this.state.cs} timeout={500}><img src={AboutPic} alt="home" className="home-img" /></Fade>
                        <Fade in={this.state.services} timeout={500}><img src={ServicesPic} alt="home" className="home-img" /></Fade>
                    </div>
                </div>
            </motion.div>
        )
    }
}

export default Home2;