import React from 'react';
import '../App.css';
import './ClinicalSupervision.css'
import { motion } from 'framer-motion';
import Header from './CSFAQHeader.png'

class CSFAQ extends React.Component {
    render() {
        return (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
            >
                <h3 className='question'>What is clinical supervision?</h3>
                <p className='answer'>Clinical supervision is a relationship that develops over time between the supervisor and supervisee, and it is a method of dialogue which influences the process of psychotherapy in multiple ways. The supervisor maintains a role of monitoring the supervisee’s abilities, which serves to protect the field of psychotherapy. Supervisors provide ongoing feedback and knowledge and work with the supervisee to attain their personal goals. Supervision protects the client first, ensures the supervisee maintains a high level of competency, and facilitates both the professional development of the supervisee as well as their self awareness. Furthermore, it focuses on the emotional experience and process of the supervisee.</p>
                <h3 className='question'>How does Tall Tree Psychotherapy approach supervision?</h3>
                <p className='answer'>OP’s approach to supervision is collaborative and multifaceted. The supervisor works with the supervisee to develop and reach their goals. At the same time, the supervisor holds the supervisee to the same standards that they hold themselves, and in accordance with the ethics and standards and practices of the field of psychotherapy as laid out by the CRPO. Our approach is for the supervisee to foster a strong relationship with their supervisor. However, we work as a team by offering support from other staff members so that supervisees can also learn from the experience and expertise of other supervisors.
                </p>
                <h3 className='question'>What are Tall Tree Psychotherapy Community Rooms?</h3>
                <p className='answer'>Peer consultation is an essential aspect of the growth and development of the psychotherapist. While supervision takes on a hierarchical and mentoring role (e.g., experienced therapists working with new), there is much learning that we gain from our peers. We believe that peer consultation outlasts the need for supervision and will eventually supplant the role of supervision in your career as you move out of your qualifying status, through supervised practice, and into independent practice.</p>
                <h3 className='question'>What if I am dealing with an urgent issue?</h3>
                <p className='answer'>We at TTP understand that there are times when difficult situations arise which may cause you to feel alone and uncertain.  This is typical when dealing with new situations.  However, we feel it is important you are supported throughout this process.  While you will maintain a relationship with your primary supervisor, receive consultative supervision from other TTP staff, and have access to extensive peer networks, we want to ensure that you are supported when urgent and difficult situations arise. </p>
                <h3 className='question'>How many hours of supervision do I need?</h3>
                <p className='answer'>The number of supervision hours depends on CRPO requirements* and what you feel you need for your own growth and development. For example, you will require more hours of supervision if you are an RP (Qualifying), as you are required to complete a certain number of supervision hours to become a Registered Psychotherapist. Similarly, you must have a specific number of hours to be classified as an RP in independent practice by the CRPO.</p>
                <p className='answer'>We ask that each individual consult with the CRPO to confirm how many hours of supervision they should be receiving. We do not take responsibility for advising you on the frequency of supervision you require, but we are open to helping you figure out what your needs are.</p>
                <p className='answer'><i>*Please consult the CRPO website for more information:</i></p>
                <p className='answer'><a href="https://www.crpo.ca/supervision/#supervision_requirements">https://www.crpo.ca/supervision/#supervision_requirements</a></p>
                <h3 className='question'>How do consultations with other supervisors work?</h3>
                <p className='answer'>It is our goal to find professional, competent, and attentive supervisors to support our supervisees. This includes supervisors with a wide range of experience and expertise. We intend to keep this in mind as we hire supervisors so that we can support our clients by catering to their interests and needs. While our supervisors will have a wide range of experience and specializations, as individuals we are all limited in our specializations. Consultations will help you achieve your learning goals.  Meetings will count as a supervision hour, however, specific confidential client information will not be discussed in these meetings. Rather, the focus will be on larger themes that you are encountering in your practice and that match up with the supervisor’s areas of expertise. The frequency of how often you meet for consultation will depend on the agreements between you and your supervisor and your particular needs and goals. You may not need or desire to use this service at all, and Tall Tree Psychotherapy will have some limitations to ensure that the needs of your clients are being met.</p>
                <h3 className='question'>How do different types of supervision count towards my hours with CRPO (individual, dyadic, group, community room)?</h3>
                <p className='answer'>One hour of Individual supervision counts as one individual hour for the CRPO.  The hour will focus entirely on your needs and client files.  This is a good option if you are not as concerned about the amount of hours you have, finances are not an issue, and you prefer or need the one on one time.</p>
                <p className='answer'>Dyadic is a great way to double your hours and receive extra learning.  While you pay for one hour, you will attend supervision with a peer and it will last 2 hours.  The focus will be on you for one hour, and then on your peer for the other hour.  Although you pay for one hour, you are able to learn from your peer AND you are able to claim both hours as individual.  It is the best value for your development.  However, there may be times when you prefer or need to meet individually.</p>
                <p className='answer'>Group supervision entails a group of 3-5 members and you will all agree on how many hours you’d like the meeting to be.  If there is a group of four that meets for 4 hours, one hour will be focused on you (this may not equal exactly 60 minutes, but roughly so).  You will pay for one hour and claim one hour of individual supervision.  You will then get to sit in and learn from the rest of your group, but you will claim 3 hours of group supervision.  These still count towards your hours, but you’ll need a certain amount of individual hours. This is better value for your learning than Dyadic, but not as helpful in getting individual hours.</p>
                <p className='answer'>The Community Room provides learning and support from your peers and it counts towards indirect hours, however, they do not count towards supervision hours. It is a different, but still important way of developing as a therapist, and it is very affordable.  However, it will be less helpful in fulfilling your requirements with the CRPO.  At Tall Tree Psychotherapy, we feel it is essential at any stage in your development and career.</p>
            </motion.div>
        )
    }
}

export default CSFAQ;