import React from 'react';
import './App.css';
import pic1 from './Services5.jpg';
import pic2 from './FAQ1.jpg';
import Topbar from './Topbar';
import Footer from './Footer';
import { AnimatePresence, motion } from 'framer-motion';
import { services1, services2, services3, services4, services5 } from './TextContent.json';
import { Link } from 'react-router-dom';

class Services extends React.Component {
    render() {
        return (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
            >
                <div className="services-container">
                    <div>
                        <div className="new-paragraph">
                            Psychotherapy services can address the following concerns:
                        </div>
                        <div className="new-paragraph">
                            <ul>
                                <li>Trauma and Posttraumatic Stress Disorder</li>
                                <li>Attention Deficit Hyperactivity Disorder (ADHD)</li>
                                <li>Anxiety, worry, and panic</li>
                                <li>Depression</li>
                                <li>Emotion Regulation</li>
                                <li>Anger Management</li>
                                <li>Grief and Loss</li>
                                <li>Life Transitions</li>
                                <li>Social Skills</li>
                                <li>Assertive Communication</li>
                                <li>Boundaries</li>
                                <li>Self-Esteem</li>
                                <li>Stress</li>
                                <li>Behavioural Concerns</li>
                                <li>Suicidal Ideation</li>
                                <li>Autism Spectrum Disorder</li>
                                <li>Self-Harm</li>
                                <li>Bipolar Disorder</li>
                                <li>Obsessive-Compulsive Disorder (OCD)</li>
                                <li>Personality Disorders</li>
                            </ul>
                        </div>
                    </div>
                    <img src={pic1} alt="image" width="40%" className="image1 serv-img" />
                </div>
                <div className="services-container">
                    <img src={pic2} alt="image" width="40%" className="image1 dis-mob" />
                    <div>
                        <div className="new-paragraph">
                            Tracy provides services <i>in person</i> and <i>virtually</i>
                        </div><br /><br />
                        <div className="new-paragraph">
                            Solmaz, Mycha, and Yulia provide services <i>virtually</i>
                        </div><br /><br />
                        <div className="new-paragraph">
                            Virtual sessions are conducted using PIPEDA and PHIPA compliant platforms (Jane Video or Zoom)
                        </div><br /><br />
                        <div className="new-paragraph">
                            Contact if you would like to schedule an appointment
                        </div><br /><br /><br /><br />
                        <div className="link-button"><Link to='/psychotherapy-services/contact'>Interested in services? Click here</Link></div>
                    </div>
                </div>
            </motion.div>
        )
    }
}

export default Services;

//<div><a href='https://talltreepsychotherapy.therabyte.app/site' className="contact-submit contact-me booked">Already booked? Click here</a></div>