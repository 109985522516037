import React from 'react';
import './App.css';
import './clinical-supervision/ClinicalSupervision.css';
import Topbar from './Topbar';
import Footer from './Footer';
import { motion } from 'framer-motion';
import { Link, Route } from 'react-router-dom';
import CSFees from './clinical-supervision/CSFees';
import CSServices from './clinical-supervision/CSServices';
import CSStory from './clinical-supervision/CSStory';
import CSFAQ from './clinical-supervision/CSFAQ';
import CSContact from './clinical-supervision/CSContact';
import Services from './Services';
import About from './About';
import FAQ from './FAQ';
import Contact from './Contact';

const subMap = {
    "therapists": "Our Therapists",
    "faq": "FAQ",
    "contact": "Contact"
}

class PsychotherapyServices extends React.Component {
    render() {
        const { match } = this.props;

        return (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
            >
                <Topbar />
                <div className="header-image cs-header">
                    <div className="services1">Psychotherapy Services</div>
                    <p className='cs-subheading'>{subMap[this.props.location.pathname.split("/")[2]] || ""}</p>
                </div>
                <div className='cs-container'>
                    <ul className='cs-subnav'>
                        <Link to="/psychotherapy-services" className="cslink">
                            <li>Services</li>
                        </Link>
                        <Link to={`${match.url}/therapists`} className="cslink">
                            <li>Our Therapists</li>
                        </Link>
                        <Link to={`${match.url}/faq`} className="cslink">
                            <li>FAQ</li>
                        </Link>
                        <Link to={`${match.url}/contact`} className="cslink">
                            <li>Contact</li>
                        </Link>
                    </ul>
                    <Route path={`${match.path}`} exact component={Services} />
                    <Route path={`${match.path}/therapists`} component={About} />
                    <Route path={`${match.path}/faq`} component={FAQ} />
                    <Route path={`${match.path}/contact`} component={Contact} />
                </div>
                <Footer />
            </motion.div >
        )
    }
}

export default PsychotherapyServices;