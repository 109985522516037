import React from 'react';
import './App.css';
import MychaPic from './MychaPic.jpeg';
import Topbar from './Topbar';
import Footer from './Footer';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import pic2 from './About2.jpg';

class Mycha extends React.Component {
	render() {
		return (
			<motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
			>
				<Topbar />
				<div className="header-image5">
					<div className="services1">Mycha Haughton-Lewis</div>
				</div>
				<div className='profile-container'>
					<div className='profile-section'>
						<img src={MychaPic} alt="Tracy Bertrim" className='profile-pic' />
						<div className='profile-overview-section'>
							<div class="profile-header">Overview</div>
							<div className='profile-overview'>Populations Served</div>
							<ul className='overview-list'>
								<li>Adolescents</li>
								<li>Adults</li>
							</ul>
							<div className='profile-overview'>Therapy Formats</div>
							<ul className='overview-list'>
								<li>Individual</li>
								<li>Couples</li>
							</ul>
							<div className='profile-overview'>Main Areas of Focus</div>
							<ul className='overview-list last-list'>
								<li>Anxiety</li>
								<li>Self Identity</li>
								<li>ADHD</li>
								<li>OCD</li>
								<li>BPD</li>
							</ul>
						</div>
					</div>
					<br />
					<div class="profile-bio">
						<div>
							<p className='profile-paragraph'>Mycha (she/her) is a registered psychotherapist (qualifying) with over a year of clinical
								experience who has worked with individuals with diverse concerns. Mycha ensures she
								provides a welcoming and safe environment for clients to explore their thoughts and
								concerns from a non-judgmental approach. Mycha believes the client is the expert of
								their own life which means the client plays a significant role in the therapeutic process.
								In addition, Mycha&#39;s approach is client-centered, ensuring a collaborative relationship
								between client and therapist. Mycha is aware that the therapeutic process is different for
								each client. She incorporates evidence-based treatments such as Acceptance and
								Commitment Therapy (ACT), Dialectical Behavioural Therapy (DBT) and Interpersonal
								and Social Rhythm Therapy (IPSRT) to provide a treatment plan based on the client&#39;s
								treatment goals.</p>
							<p className='profile-paragraph'>**Mycha provides  <i>virtual</i> therapy services</p>
							<div class="services-button"><Link to='/contact' className="contact-submit contact-me">Interested in services? Click here</Link></div>
						</div>
						<img src={pic2} alt="image" className="image2 desktop" />
					</div><br></br><br></br>
				</div>
				<Footer />
			</motion.div>
		)
	}
}

export default Mycha;